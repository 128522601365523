do ($ = jQuery) ->
  $ ->
    ($ '.carousel').each ->
      $carousel = ($ this)
      $carousel.addClass 'start'
      ($carousel.find '.group').first().addClass 'active'
      ($ 'body').on 'mousedown touchstart', (e) ->

        pos = $carousel.offset()
        pageX = e.pageX || e.originalEvent.pageX
        pageY = e.pageY || e.originalEvent.pageY
        clicked_within_vertical = pageY > pos.top && pageY < pos.top + $carousel.height()

        if clicked_within_vertical

          #console.log 'clicked within vertical'
          $carousel.removeClass 'start end'
          if pageX < pos.left

            #console.log 'pageX less than pos.left'
            idx = Math.max 0, -1 + ($carousel.find '.active').index()
            (((($carousel.find '.group').css 'transform', "translateX(-#{(idx)*100}%)").removeClass 'active').eq idx).addClass 'active'
            $carousel.addClass 'start' if idx == 0
          else if pageX > pos.left + $carousel.width()

            #console.log 'pageX greater than pos.left'
            count = ($carousel.find '.group').length - 1
            idx = Math.min count, 1 + ($carousel.find '.active').index()
            (((($carousel.find '.group').css 'transform', "translateX(-#{(idx)*100}%)").removeClass 'active').eq idx).addClass 'active'
            $carousel.addClass 'end' if idx == count

    #console.log( (x + " " for x in jQuery('#pmb-detail-single option').text()) )
